import useSWR from "swr";

type LineAccountCountPerVisitCounts = {
  visit_count: number;
  count: number;
}[]
export const useFetchLineAccountCounts = (restaurantId?: string) => {
  const fetcherKey = restaurantId ? `external_api/restaurants/${restaurantId}/line/accounts/counts/per-visit-counts/` : null;
  const { data: lineAccountCounts, error, isLoading } = useSWR<LineAccountCountPerVisitCounts>(fetcherKey)
  return { lineAccountCounts, isLoading, error };
};