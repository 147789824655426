import { Route, Routes } from "react-router-dom"
import { NotFound } from "../components/pages/error/NotFound";
import { MenuLiffApp } from "../components/pages/menu/MenuLiffApp";
import { TableAuth } from "../components/pages/table/TableAuth";
import { CheckTableSecurity } from "../components/pages/table/CheckTableSecurity";
import { SoCouponLiffApp } from "../components/pages/soCoupon/SoCouponLiffApp";
import { SoCouponSurvey } from "../components/pages/soCoupon/SoCouponSurvey";
import { CustomerSoCoupon } from "../components/pages/soCoupon/CustomerSoCoupon";
import { NoUseLineCustomerMenu } from "../components/pages/menu/NoUseLineCustomerMenu";
import { LineUsageChecker } from "../components/pages/table/LineUsageChecker";
import { Logo } from "../components/pages/Logo";
import { PostKuchikomyuOverallScore } from "../components/pages/kuchikomyu/PostKuchikomyuOverallScore";
import { AnswerKuchikomyuSurvey } from "../components/pages/kuchikomyu/AnswerKuchikomyuSurvey";
import { Thanks } from "../components/pages/Thanks";
import { Test } from "../components/pages/Test";
import { ManLaboLiffApp } from "../components/pages/manzokudoLabo/ManLaboLiffApp";
import { ServerError } from "../components/pages/error/ServerError";
import { LineUsageGuide } from "../components/pages/error/LineUsageGuide";
import { ManLaboTest } from "../components/pages/e2etest/ManlaboTest";
import { TransitionPage } from "../components/pages/e2etest/TransitionPage";
import { MenuTest } from "../components/pages/e2etest/MenuTest";
import { SoCouponTest } from "../components/pages/e2etest/SoCouponTest";
import { Test2 } from "../components/pages/Test2";
import { PostKuchikomyuOverallScoreEn } from "../components/pages/kuchikomyu/PostKuchikomyuOverallScoreEn";
import { AnswerKuchikomyuSurveyEn } from "../components/pages/kuchikomyu/AnswerKuchikomyuSurveyEn";
import { ThanksEn } from "../components/pages/ThanksEn";
import { VisitLiffApp } from "../components/pages/VisitLiffApp";
import { NotifyBotLiffApp } from "../components/pages/externalApi/NotifyBotLiffApp";
import { GoogleReviewRepliesLiffApp } from "../components/pages/externalApi/google/GoogleReviewRepliesLiffApp";
import { ReviewReply } from "../components/pages/externalApi/google/ReviewReply";
import { ManagerRoutes } from "./ManagerRoutes";
import { AuthRoutes } from "./AuthRoutes";
import { CorporationRoutes } from "./CorporationRoutes";
import { SalesmanRoutes } from "./SalesmanRoutes";
import { RestaurantRoutes } from "./RestaurantRoutes";
import { UnregisteredQrAlert } from "../components/pages/table/UnregisteredQrAlert";
import { BadRequest } from "../components/pages/error/BadRequest";
import { CouponRoulette } from "../components/pages/games/roulette/CouponRoulette";
import { RouletteLiffApp } from "../components/pages/games/roulette/RouletteLiffApp";
import { CustomerMembershipRoutes } from "./CustomerMembershipRoutes";
import { KuitanAgentRoutes } from "./KuitanAgentRoutes";
import { AdminRoutes } from "./AdminRoutes";
import { StaffSurveyLiffApp } from "../components/pages/restaurantStaff/StaffSurveyLiffApp";
import { MembershipRegistrationLiffApp } from "../components/pages/MembershipRegistrationLiffApp";
import { RestaurantSurveyLiffApp } from "../components/pages/restaurantStaff/RestaurantSurveyLiffApp";


export const Router = () => {
  return (
    <Routes>
      <Route path="/" element={<Logo />} />
      {RestaurantRoutes}
      {SalesmanRoutes}
      {CorporationRoutes}
      {ManagerRoutes}
      {KuitanAgentRoutes}
      {AuthRoutes}
      {AdminRoutes}

      {CustomerMembershipRoutes}

      {/* menu */}
      <Route path="menu/line-login/*" element={<MenuLiffApp />} />
      <Route path="menu/:restaurantId/:tableId" element={<NoUseLineCustomerMenu />} />
      <Route path="line-usage-check/:restaurantId/:tableId" element={<LineUsageChecker />} />

      {/* table */}
      <Route path="table-auth/:restaurantId/:tableId" element={<TableAuth />} />
      <Route path="check-table-security/:restaurantId/:tableId" element={<CheckTableSecurity />} />

      {/* socoupon */}
      <Route path="socoupon/line-login/*" element={<SoCouponLiffApp />} />
      <Route path="socoupon/survey/:restaurantId" element={<SoCouponSurvey />} />
      <Route path="customers/:lineAccountId/socoupon" element={<CustomerSoCoupon />} />

      {/* kuchikomyu */}
      <Route path="kuchikomyu/post-score/:restaurantId" element={<PostKuchikomyuOverallScore />} />
      <Route path="kuchikomyu/post-score/en/:restaurantId" element={<PostKuchikomyuOverallScoreEn />} />
      <Route path="kuchikomyu/survey/:restaurantId" element={<AnswerKuchikomyuSurvey />} />
      <Route path="kuchikomyu/survey/en/:restaurantId" element={<AnswerKuchikomyuSurveyEn />} />

      {/* manzokudo-labo */}
      <Route path="manzokudo-labo/line-login/*" element={<ManLaboLiffApp />} />

      {/* google */}
      <Route path="notify-bot/line-login/*" element={<NotifyBotLiffApp />} />
      <Route path="line/login/google/reviews/replies/" element={<GoogleReviewRepliesLiffApp />} />
      <Route path="line/login/google/reviews/replies/:replyId" element={<ReviewReply />} />

      {/* roulette */}
      <Route path="roulette/line-login/*" element={<RouletteLiffApp />} />
      <Route path="roulette/:restaurantId" element={<CouponRoulette />} />

      {/* restaurant-staff */}
      <Route path="staff-survey/line-login/*" element={<StaffSurveyLiffApp />} />
      <Route path="restaurant-survey/line-login/*" element={<RestaurantSurveyLiffApp />} />

      {/* others */}
      <Route path="visit/line-login/*" element={<VisitLiffApp />} />
      <Route path="membership-registration/line-login/*" element={<MembershipRegistrationLiffApp />} />

      <Route path="line-usage-guide" element={<LineUsageGuide />} />
      <Route path="thanks" element={<Thanks />} />
      <Route path="thanks/en" element={<ThanksEn />} />
      <Route path="qr-alert/unregistered" element={<UnregisteredQrAlert />} />
      <Route path="*" element={<NotFound />} />
      <Route path="server-error" element={<ServerError />} />
      <Route path="bad-request" element={<BadRequest />} />

      <Route path="test" element={<Test />} />
      <Route path="test/:couponId" element={<Test2 />} />
      <Route path="transition-test" element={<TransitionPage />} />
      <Route path="manlabo-test" element={<ManLaboTest />} />
      <Route path="menu-test" element={<MenuTest />} />
      <Route path="socoupon-test" element={<SoCouponTest />} />
    </Routes>
  )
};