import { Route } from 'react-router-dom';
import { RestaurantBaseInfoEdit } from '../components/pages/restaurants/RestaurantBaseInfoEdit';
import { DashboardWrapper } from '../components/template/DashboardWrapper';
import { UsedCouponUsers } from '../components/pages/UsedCouponUsers';
import { VisitQrCode } from '../components/pages/externalApi/line/VisitQrCode';
import { Notices } from '../components/pages/notice/Notices';
import { Notice } from '../components/pages/notice/Notice';
import RestaurantBase from '../components/template/base/RestaurantBase';
import { RestaurantBaseInfo } from '../components/pages/restaurants/RestaurantBaseInfo';
import { AddOnsRoutes } from './AddOnsRoutes';


export const RestaurantRoutes = (
  <Route path="restaurants/:restaurantId" element={<RestaurantBase />}>
    <Route path="" element={<RestaurantBaseInfo />} />
    <Route path="edit" element={<RestaurantBaseInfoEdit />} />
    <Route path="notices" element={<Notices />} />
    <Route path="notices/:noticeId" element={<Notice />} />
    <Route path="dashboard" element={<DashboardWrapper/>} />
    <Route path="used-coupon-users" element={<UsedCouponUsers />} />
    <Route path="visit/qr-code" element={<VisitQrCode />} />
    {AddOnsRoutes}
  </Route>
);